import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-66e530c8"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "page-con"
};
var _hoisted_2 = {
  class: "left-panel"
};
var _hoisted_3 = {
  class: "right-panel"
};
var _hoisted_4 = {
  class: "main-title"
};
var _hoisted_5 = {
  class: "sub-title"
};
var _hoisted_6 = {
  key: 0,
  class: "brief"
};
var _hoisted_7 = {
  key: 1,
  class: "brief"
};
var _hoisted_8 = {
  key: 2,
  class: "brief"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.contract, function (item, idx) {
    return _openBlock(), _createElementBlock("div", {
      class: "left-tab-item",
      key: idx
    }, "Contract: " + _toDisplayString(idx), 1);
  }), 128))]), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.contract, function (item, idx) {
    return _openBlock(), _createElementBlock("div", {
      key: idx
    }, [_createElementVNode("div", _hoisted_4, "Contract: " + _toDisplayString(idx), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, function (iitem, iidx) {
      return _openBlock(), _createElementBlock("div", {
        key: iidx
      }, [_createElementVNode("div", _hoisted_5, _toDisplayString(iitem.method), 1), iitem.methodType == 'view' ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("pre", null, "const result = await Contract." + _toDisplayString(idx) + "." + _toDisplayString(iitem.methodName) + "(" + _toDisplayString(iitem.params) + ")", 1)])) : iitem.methodType == 'payable' ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("pre", null, "const sendValue = 1; // 带精度\r\nContract." + _toDisplayString(idx) + ".send(sendValue)." + _toDisplayString(iitem.methodName) + "(" + _toDisplayString(iitem.params) + ").then(result => {\r\n    console.log(result)\r\n}).catch(err => {\r\n    console.log(err)\r\n})\r\n                    ", 1)])) : (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("pre", null, "Contract." + _toDisplayString(idx) + "." + _toDisplayString(iitem.methodName) + "(" + _toDisplayString(iitem.params) + ").then(result => {\r\n    console.log(result)\r\n}).catch(err => {\r\n    console.log(err)\r\n})\r\n                    ", 1)]))]);
    }), 128))]);
  }), 128))])]);
}